<template>
    <div>
    <Navigation/>
        <main class="main">
            <b-overlay :show="isLoadingContent" class="loader-overlay" >
                <div class="init" v-show="!urlBankExist" >
                    <a class="btn-back" @click="goToList" v-if="showBtnBack"> {{ $t('payment.backToHome') }}</a>                        
                </div>   
                <div class="section-payment container" v-show="urlBankExist">
                    <div class="btn-forms-payment">
                        <b-button :variant="isPaymentCard ? 'primary' : 'dark' " @click="setIsPaymentCard(true)"> {{ $t('payment.creditCardPayment') }} </b-button>                
                        <b-button :variant="!isPaymentCard ? 'primary' : 'dark'" @click="setIsPaymentCard(false)" > {{ $t('payment.contactCenterPayment') }} </b-button>                                        
                    </div>
                                
                    <iframe
                        v-show="!isLoadingContent && isPaymentCard"
                        class="iframe"
                        
                        :src="orderCreated.payment.url"
                        scrolling="no"
                        id="iframeBank"   
                        @load="loadIFrame"
                    >                            
                    </iframe>
                    <div v-show="!isLoadingContent && !isPaymentCard" class="mt-2 data-contact-center">
                            <h3 class="title-cc-payment mb-2">
                               
                                {{ $t('payment.contactCenterPaymentTitle') }}
                            </h3>
                            <p class="paragraph-cc-payment mb-2 our">
                                {{ $t('payment.theOrderNumberIs') }} : <b-badge variant="dark">{{ orderCreated.invoice }}</b-badge>
                            </p>
                            <p class="paragraph-cc-payment our">
                                {{ $t('payment.contactCenterPaymentParagraph') }}
                            </p>
                            <div class="contact">
                                <a href="tel:18007289062">USA & CAN: 1 800 728 90 62</a>
                                <a href="tel:9849800529">MEX: (984) 980 0529</a>
                                <a href="mailto:experiences@marholidayscaribe.com">experiences@marholidayscaribe.com</a>
                            </div>                           

                    </div> 
                </div>         
            </b-overlay>                               
        </main>
        <Footer/>
    </div>
</template>

<script>

import { mapState, mapActions,  mapMutations  } from 'vuex'

import Navigation from '@/modules/shop/components/experiences/NavigationLite'
import Footer from '@/modules/shop/components/experiences/FooterLite'

    export default {
        
        components: {     
            Navigation,           
            Footer,        
        },      
        created(){
            document.title = `${process.env.VUE_APP_NAME } | Payment`     
            this.checkOrderState()        
        },
       
        data(){
            return {                 
                isPaymentCard: true,                                 
                isLoadingContent: false, 
                showBtnBack: false,                
            }
        },
        computed:{
			...mapState('auth',['queryParams']),
            ...mapState('shop',['orderCreated']),
            urlBankExist(){
                let existUrl = false
                if( this.orderCreated ) {
                    const { payment } = this.orderCreated
                    if(payment){
                        const { error, url } = payment
                        existUrl = ( this.orderCreated && payment && !error && url != '' )
                    }
                }
                return existUrl
            },
           
        },
        methods:{
            ...mapActions('shop',['checkOrderStatusPayment','retryPaymentOrder']),
            ...mapMutations('shop',['setStatusPaymentOrder','resetCartCustomerBreakdownOrder','setOrderCreated']), 
            checkOrderState(){
                this.isLoadingContent = true                
                setTimeout(() => {
                    this.isLoadingContent = false   
                    if(!this.urlBankExist){
                        this.showBtnBack = true
                    }                 
                }, 2000);
            },
          
            goToList(){      
                this.$router.push({ name:'results', query: {...this.queryParams} })
            },
            async checkStatusOrder() {
                if(this.orderCreated && this.orderCreated.order){
                    const respuesta = await this.checkOrderStatusPayment(this.orderCreated.order)   
                    return respuesta                     
                }
            },
            async loadIFrame(){
                this.isLoadingContent = true
                const { transactionstatus } = await this.checkStatusOrder()
                if( transactionstatus.name === 'Approved'){
                    // this.$router.push({ name:'thank-you-page', params: {order:this.orderCreated.order} })
                    // this.resetCartCustomerBreakdownOrder(null) 
                }     
                console.log('loadIFrame')            
                this.isLoadingContent = false
            },
                 
            async getSrc(){    
                if(this.orderCreated && this.orderCreated.order){                                        
                    if( this.paymentIsAproved() ){
                        this.$router.push({ name:'thank-you-page', params: {order:this.orderCreated.order} })
                        this.resetCartCustomerBreakdownOrder(null)  
                    }
                         
                } 
            },
            paymentIsAproved(){
                                  
                let isApproved = false
                // const url = new URL('https://sales.thefives.com.mx/payment/response?success=false&nuAut=&nbResponse=Aprobado')
                const url = new URL(document.getElementById('iframeBank').src)
                console.log(url.searchParams.get('nbResponse'))
                // const searchParams = url.searchParams // ?success=false&nuAut=&nbResponse=Aprobado              
                const responseAproved = url.searchParams.get('nbResponse') //busco si viene el query params nbResponse, sino viene como null                
                isApproved = responseAproved && responseAproved === 'Aprobado' ? true : false // responseAproved es diferente de nulo y es igual a Aprobado                
                console.log({isApproved})
                return isApproved
                
            },
            setIsPaymentCard( isPaymentCard ){
                this.isPaymentCard = isPaymentCard
            }
           
            
        },
        watch:{
            
        }
    }
</script>

<style lang="scss" scoped>
@import "@/assets/scss/payment.scss";

.init{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.data-contact-center{
    text-align: center;
}
.title-cc-payment{
    margin-top: 1rem;
    color: black;
    font-weight: bold;
    font-size: 1.8rem;
}
.paragraph-cc-payment{
    margin-top: 1rem;
    color: black;
    font-weight: bold;
    font-size: 1.3rem;
}
.our{
    font-size: 1.7rem;
}
.contact {
    margin-top: 1rem;
}
.contact a{
    display: block;
    margin-bottom: 1rem;
    font-weight: 600;
    color: #000000;
    font-size: 1.1rem;
}
</style>